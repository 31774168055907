import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import tw, { styled } from "twin.macro";

import StyledHeroImage from "./HeroImage";
import BaseButton from "../BaseButton";

import Logo from "../../images/solanalabs_white.inline.svg";

const Container = styled.div`
  ${tw`
    relative 
    sm:max-w-xl md:max-w-3xl
    text-white text-xl text-left 
    px-6 sm:px-12`}
`;

const LogoWrapper = styled.div`
  ${tw`
   mb-4
   md:mb-12
  `}
`;

/**
 * Top section (Hero section).
 *
 * @constructor
 */
const FirstSection = (): React.ReactElement => {
  const { hero } = useStaticQuery(
    graphql`
      query {
        hero: markdownRemark(frontmatter: { slug: { eq: "hero" } }) {
          frontmatter {
            title
          }
          rawMarkdownBody
        }
      }
    `
  );
  return (
    <StyledHeroImage>
      <Container>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <h1 className="font-bold tracking-tight">{hero.frontmatter.title}</h1>
        <p>{hero.rawMarkdownBody}</p>
        <a href="https://boards.greenhouse.io/solana" rel="noopener noreferrer">
          <BaseButton>View open roles</BaseButton>
        </a>
      </Container>
    </StyledHeroImage>
  );
};

export default FirstSection;
