import * as React from "react";

import Layout from "../components/Layout";
import FirstSection from "../components/firstSection";

const IndexPage = (): React.ReactElement => (
  <Layout>
    <FirstSection />
  </Layout>
);

export default IndexPage;
