import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import tw, { styled } from "twin.macro";

import BackgroundImage from "gatsby-background-image";

type Props = {
  children?: React.ReactNode;
  className?: string;
};

/**
 * Hero Image - To be replaced by video.
 *
 * @param className
 * @param children
 * @constructor
 */
const HeroImage = ({ className, children }: Props): React.ReactElement => {
  const { index } = useStaticQuery(
    graphql`
      query {
        index: file(relativePath: { eq: "heroimage.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 3840) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  );

  // Set ImageData including the mobile background.
  const imageData = [
    {
      ...index.childImageSharp.fluid,
    },
  ];

  return (
    <BackgroundImage
      Tag="div"
      className={className}
      fluid={imageData}
      style={{
        backgroundPosition: "",
        backgroundSize: "",
      }}
      backgroundColor={`#000`}
    >
      {children}
    </BackgroundImage>
  );
};

const StyledHeroImage = styled(HeroImage)`
  ${tw`
    min-h-screen w-full
    py-0 inset-0 
    flex flex-col justify-center 
  `}
  &:before,
  &:after {
    ${tw`bg-half bg-right-bottom sm:bg-contain
    `}
  }
`;

export default StyledHeroImage;
